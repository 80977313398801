<template>
    <slot/>
</template>

<script setup>
const localeHead = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: 'id',
    addSeoAttributes: true
})

useHead(localeHead)
</script>